<template>
  <section>
    <p class="text-h6 secondary--text">
      Seguimiento de orden de compra {{ seguimientoContrato?.contrato?.numero }}
    </p>
    <v-row no-gutters align="center">
      <v-col v-if="selectedProvider && seguimientoContrato" cols="12" md="3">
        <info-producto-component :showEyeButton="handleVisibility" hideMount />
      </v-col>
      <v-col cols="12" md="7">
        <p class="text-h6 secondary--text">
          {{ seguimientoContrato?.contrato?.nombre_contrato || "" }}
        </p>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn
          v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
          :disabled="
            Object.keys(montos).length == 0 || montos.monto_restante > 0
          "
          block
          class="white--text"
          color="success"
          @click="liquidacionModal = true"
          >Liquidar compra</v-btn
        >
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      color="secondary"
      class="mt-5"
      background-color="bgMinsal"
      center-active
      show-arrows
    >
      <v-tab v-for="item in tabsFiltered" :key="item.tab">
        <v-icon color="secondary" small class="mr-2">
          {{ item.icon }}
        </v-icon>
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="slide-y-transition"
        reverse-transition="fade-transition"
        v-for="item in tabsFiltered"
        :key="item.tab"
      >
        <v-card flat color="bgMinsal">
          <component :is="item.content" class="pa-5"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <dialogoConfirmacion
      :show="liquidacionModal"
      title="¿Desea proseguir con la etapa de liquidación de orden de compra?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="liquidacionModal = false"
      @confirm="liquidarCompra()"
    />
    <v-btn color="secondary" outlined class="mt-15" @click="$router.back()"
      >Regresar</v-btn
    >
  </section>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import infoProductoComponent from "@/components/InfoProductoComponent.vue";
import perfilProveedorComponent from "@/components/PerfilProveedorComponent";
import infoOrdenCompraComponent from "./components/InfoOrdenCompraComponent";
import EtapaSeguimientoComponentV1 from "./components/EtapaSeguimientoComponentV1";
import ListadoPagosComponentV1 from "./components/ListadoPagosComponentV1";
import generalComponent from "./components/generalComponent.vue";
import DocumentosComponent from "./components/DocumentosComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import SancionesComponent from "./components/liquidarOrden/SancionesComponent.vue";
import ReporteSancionComponent from "./components/liquidarOrden/ReporteSancionComponent.vue";
export default {
  name: "seguimientoOrdenCompraView",
  components: {
    perfilProveedorComponent,
    infoOrdenCompraComponent,
    EtapaSeguimientoComponentV1,
    ListadoPagosComponentV1,
    DocumentosComponent,
    infoProductoComponent,
    generalComponent,
    dialogoConfirmacion,
    SancionesComponent,
    ReporteSancionComponent,
  },
  props: {},
  data: () => ({
    tab: null,
    porcentajeSeguimiento: 0,
    infoOrdenCompra: {
      fechaHoraContratacion: "06/09/2022 23:59",
      numOrdenCompra: "0100-2022-OC5165",
      numInternoOrdenCompra: "C-LG-5165",
      montoAdjudicado: "$10,000.00",
    },
    liquidacionModal: false,
  }),
  computed: {
    ...mapState("procesoCompra", ["idOrdenCompra"]),
    ...mapState("seguimientoOrden", ["seguimientoContrato", "montos"]),
    ...mapState("agregarInsumo", ["selectedProvider"]),
    porcentaje() {
      return (this.porcentajeSeguimiento * 100).toFixed(2);
    },
    handleVisibility() {
      if (this.seguimientoContrato) {
        const { contrato } = this.seguimientoContrato;
        return contrato?.id_forma_contratacion !== 6;
      } else return false;
    },
    tabsFiltered() {
      let tabs = [];

      if (
        ![5, 6].includes(
          this.seguimientoContrato?.contrato?.id_forma_contratacion
        )
      ) {
        tabs = [
          {
            tab: "Etapa",
            content: "EtapaSeguimientoComponentV1",
            icon: "mdi-map-marker-radius",
          },
          {
            tab: "Pagos",
            content: "ListadoPagosComponentV1",
            icon: "mdi-currency-usd",
          },
          /* {
          tab: "Sanciones",
          content: "SancionesComponent",
          icon: "mdi-clipboard-text-clock-outline",
        }, */
          {
            tab: "Sanciones",
            content: "ReporteSancionComponent",
            icon: "mdi-clipboard-text-clock-outline",
          },
          {
            tab: "Documentos",
            content: "DocumentosComponent",
            icon: "mdi-file-document-multiple-outline",
          },
          {
            tab: "General",
            content: "generalComponent",
            icon: "mdi-information-outline",
          },
        ];
      } else {
        tabs = [
          {
            tab: "Etapa",
            content: "EtapaSeguimientoComponentV1",
            icon: "mdi-map-marker-radius",
          },
          {
            tab: "Pagos",
            content: "ListadoPagosComponentV1",
            icon: "mdi-currency-usd",
          },
          {
            tab: "Documentos",
            content: "DocumentosComponent",
            icon: "mdi-file-document-multiple-outline",
          },
          {
            tab: "General",
            content: "generalComponent",
            icon: "mdi-information-outline",
          },
        ];
      }

      return tabs;
    },
  },
  watch: {},
  methods: {
    ...mapActions("seguimientoOrden", ["getContrato"]),
    ...mapMutations("procesoCompra", ["setIdOrdenCompra"]),
    ...mapMutations("agregarInsumo", ["setCurrentProvider", "setProviderInfo"]),
    ...mapMutations("seguimientoOrden", ["obtenerContrato", "setMontos"]),
    async getProgresoSeguimiento() {
      const { status, data } =
        await this.services.ContratoService.getProgresoEtapasSeguimiento(
          this.idOrdenCompra
        );
      if (status == 200) {
        this.porcentajeSeguimiento = data.progreso;
      }
    },
    liquidarCompra() {
      this.$router.push({
        name: "liquidacion-orden-compra",
        params: { idContrato: Number(this.$route.params.idContrato) },
      });
    },
    async getMontosContrato() {
      const { status, data } =
        await this.services.ContratoService.getMontosPagos(
          this.$route.params.idContrato
        );
      if (status == 200) {
        this.setMontos(data);
      }
    },
  },
  mounted() {},
  async created() {
    this.setIdOrdenCompra(this.$route.params.idContrato);
    this.getMontosContrato();
    await this.getContrato(this.$route.params.idContrato);
    this.setCurrentProvider(this.$route.params.idProveedor);
    this.getProgresoSeguimiento();
  },
  unmounted() {
    this.obtenerContrato(null);
    this.setProviderInfo({});
  },
  beforeDestroy() {
    this.setMontos({});
    this.setCurrentProvider(null);
  },
};
</script>
